
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("codecrafters-frontend/adapters/application", function(){ return i("codecrafters-frontend/adapters/application.ts");});
d("codecrafters-frontend/app", function(){ return i("codecrafters-frontend/app.js");});
d("codecrafters-frontend/config/environment", function(){ return i("codecrafters-frontend/config/environment.js");});
d("codecrafters-frontend/instance-initializers/current-user", function(){ return i("codecrafters-frontend/instance-initializers/current-user.ts");});
d("codecrafters-frontend/instance-initializers/feature-flag-syncer", function(){ return i("codecrafters-frontend/instance-initializers/feature-flag-syncer.ts");});
d("codecrafters-frontend/instance-initializers/force-update", function(){ return i("codecrafters-frontend/instance-initializers/force-update.ts");});
d("codecrafters-frontend/instance-initializers/page-view-tracker", function(){ return i("codecrafters-frontend/instance-initializers/page-view-tracker.ts");});
d("codecrafters-frontend/instance-initializers/posthog", function(){ return i("codecrafters-frontend/instance-initializers/posthog.ts");});
d("codecrafters-frontend/instance-initializers/preload-highlighter", function(){ return i("codecrafters-frontend/instance-initializers/preload-highlighter.ts");});
d("codecrafters-frontend/instance-initializers/sentry", function(){ return i("codecrafters-frontend/instance-initializers/sentry.ts");});
d("codecrafters-frontend/instance-initializers/time", function(){ return i("codecrafters-frontend/instance-initializers/time.ts");});
d("codecrafters-frontend/instance-initializers/user-syncer", function(){ return i("codecrafters-frontend/instance-initializers/user-syncer.ts");});
d("codecrafters-frontend/instance-initializers/web-vitals", function(){ return i("codecrafters-frontend/instance-initializers/web-vitals.ts");});
d("codecrafters-frontend/mixins/is-comment", function(){ return i("codecrafters-frontend/mixins/is-comment.js");});
d("codecrafters-frontend/mixins/viewable", function(){ return i("codecrafters-frontend/mixins/viewable.js");});
d("codecrafters-frontend/mixins/votable", function(){ return i("codecrafters-frontend/mixins/votable.ts");});
d("codecrafters-frontend/models/affiliate-earnings-payout", function(){ return i("codecrafters-frontend/models/affiliate-earnings-payout.ts");});
d("codecrafters-frontend/models/affiliate-link", function(){ return i("codecrafters-frontend/models/affiliate-link.ts");});
d("codecrafters-frontend/models/affiliate-referral", function(){ return i("codecrafters-frontend/models/affiliate-referral.ts");});
d("codecrafters-frontend/models/analytics-event", function(){ return i("codecrafters-frontend/models/analytics-event.ts");});
d("codecrafters-frontend/models/autofix-request", function(){ return i("codecrafters-frontend/models/autofix-request.ts");});
d("codecrafters-frontend/models/badge-award", function(){ return i("codecrafters-frontend/models/badge-award.ts");});
d("codecrafters-frontend/models/badge", function(){ return i("codecrafters-frontend/models/badge.ts");});
d("codecrafters-frontend/models/buildpack", function(){ return i("codecrafters-frontend/models/buildpack.ts");});
d("codecrafters-frontend/models/charge", function(){ return i("codecrafters-frontend/models/charge.ts");});
d("codecrafters-frontend/models/code-walkthrough", function(){ return i("codecrafters-frontend/models/code-walkthrough.ts");});
d("codecrafters-frontend/models/community-course-stage-solution-comment", function(){ return i("codecrafters-frontend/models/community-course-stage-solution-comment.ts");});
d("codecrafters-frontend/models/community-course-stage-solution", function(){ return i("codecrafters-frontend/models/community-course-stage-solution.ts");});
d("codecrafters-frontend/models/community-solution-evaluation", function(){ return i("codecrafters-frontend/models/community-solution-evaluation.ts");});
d("codecrafters-frontend/models/community-solution-evaluator", function(){ return i("codecrafters-frontend/models/community-solution-evaluator.ts");});
d("codecrafters-frontend/models/concept-engagement", function(){ return i("codecrafters-frontend/models/concept-engagement.ts");});
d("codecrafters-frontend/models/concept-group", function(){ return i("codecrafters-frontend/models/concept-group.ts");});
d("codecrafters-frontend/models/concept-question", function(){ return i("codecrafters-frontend/models/concept-question.ts");});
d("codecrafters-frontend/models/concept", function(){ return i("codecrafters-frontend/models/concept.ts");});
d("codecrafters-frontend/models/contest", function(){ return i("codecrafters-frontend/models/contest.ts");});
d("codecrafters-frontend/models/course-definition-update", function(){ return i("codecrafters-frontend/models/course-definition-update.ts");});
d("codecrafters-frontend/models/course-extension-activation", function(){ return i("codecrafters-frontend/models/course-extension-activation.ts");});
d("codecrafters-frontend/models/course-extension-idea-vote", function(){ return i("codecrafters-frontend/models/course-extension-idea-vote.ts");});
d("codecrafters-frontend/models/course-extension-idea", function(){ return i("codecrafters-frontend/models/course-extension-idea.ts");});
d("codecrafters-frontend/models/course-extension", function(){ return i("codecrafters-frontend/models/course-extension.ts");});
d("codecrafters-frontend/models/course-idea-vote", function(){ return i("codecrafters-frontend/models/course-idea-vote.ts");});
d("codecrafters-frontend/models/course-idea", function(){ return i("codecrafters-frontend/models/course-idea.ts");});
d("codecrafters-frontend/models/course-insights-dashboard", function(){ return i("codecrafters-frontend/models/course-insights-dashboard.ts");});
d("codecrafters-frontend/models/course-language-configuration", function(){ return i("codecrafters-frontend/models/course-language-configuration.ts");});
d("codecrafters-frontend/models/course-language-request", function(){ return i("codecrafters-frontend/models/course-language-request.ts");});
d("codecrafters-frontend/models/course-leaderboard-entry", function(){ return i("codecrafters-frontend/models/course-leaderboard-entry.ts");});
d("codecrafters-frontend/models/course-participation", function(){ return i("codecrafters-frontend/models/course-participation.ts");});
d("codecrafters-frontend/models/course-stage-comment", function(){ return i("codecrafters-frontend/models/course-stage-comment.ts");});
d("codecrafters-frontend/models/course-stage-completion", function(){ return i("codecrafters-frontend/models/course-stage-completion.ts");});
d("codecrafters-frontend/models/course-stage-feedback-submission", function(){ return i("codecrafters-frontend/models/course-stage-feedback-submission.ts");});
d("codecrafters-frontend/models/course-stage-language-guide", function(){ return i("codecrafters-frontend/models/course-stage-language-guide.ts");});
d("codecrafters-frontend/models/course-stage-participation-analysis", function(){ return i("codecrafters-frontend/models/course-stage-participation-analysis.ts");});
d("codecrafters-frontend/models/course-stage-participation", function(){ return i("codecrafters-frontend/models/course-stage-participation.ts");});
d("codecrafters-frontend/models/course-stage-screencast", function(){ return i("codecrafters-frontend/models/course-stage-screencast.ts");});
d("codecrafters-frontend/models/course-stage-solution", function(){ return i("codecrafters-frontend/models/course-stage-solution.ts");});
d("codecrafters-frontend/models/course-stage", function(){ return i("codecrafters-frontend/models/course-stage.ts");});
d("codecrafters-frontend/models/course-tester-version", function(){ return i("codecrafters-frontend/models/course-tester-version.ts");});
d("codecrafters-frontend/models/course", function(){ return i("codecrafters-frontend/models/course.ts");});
d("codecrafters-frontend/models/downvote", function(){ return i("codecrafters-frontend/models/downvote.ts");});
d("codecrafters-frontend/models/feature-suggestion", function(){ return i("codecrafters-frontend/models/feature-suggestion.ts");});
d("codecrafters-frontend/models/free-usage-grant", function(){ return i("codecrafters-frontend/models/free-usage-grant.ts");});
d("codecrafters-frontend/models/github-app-installation", function(){ return i("codecrafters-frontend/models/github-app-installation.ts");});
d("codecrafters-frontend/models/github-repository-sync-configuration", function(){ return i("codecrafters-frontend/models/github-repository-sync-configuration.ts");});
d("codecrafters-frontend/models/individual-checkout-session", function(){ return i("codecrafters-frontend/models/individual-checkout-session.ts");});
d("codecrafters-frontend/models/individual-payment-method-update-request", function(){ return i("codecrafters-frontend/models/individual-payment-method-update-request.ts");});
d("codecrafters-frontend/models/invoice", function(){ return i("codecrafters-frontend/models/invoice.ts");});
d("codecrafters-frontend/models/language", function(){ return i("codecrafters-frontend/models/language.ts");});
d("codecrafters-frontend/models/leaderboard-entry", function(){ return i("codecrafters-frontend/models/leaderboard-entry.ts");});
d("codecrafters-frontend/models/leaderboard", function(){ return i("codecrafters-frontend/models/leaderboard.ts");});
d("codecrafters-frontend/models/onboarding-survey", function(){ return i("codecrafters-frontend/models/onboarding-survey.ts");});
d("codecrafters-frontend/models/perk", function(){ return i("codecrafters-frontend/models/perk.ts");});
d("codecrafters-frontend/models/promotional-discount", function(){ return i("codecrafters-frontend/models/promotional-discount.ts");});
d("codecrafters-frontend/models/referral-activation", function(){ return i("codecrafters-frontend/models/referral-activation.ts");});
d("codecrafters-frontend/models/referral-link", function(){ return i("codecrafters-frontend/models/referral-link.ts");});
d("codecrafters-frontend/models/regional-discount", function(){ return i("codecrafters-frontend/models/regional-discount.ts");});
d("codecrafters-frontend/models/repository-stage-list-item", function(){ return i("codecrafters-frontend/models/repository-stage-list-item.ts");});
d("codecrafters-frontend/models/repository-stage-list", function(){ return i("codecrafters-frontend/models/repository-stage-list.ts");});
d("codecrafters-frontend/models/repository", function(){ return i("codecrafters-frontend/models/repository.ts");});
d("codecrafters-frontend/models/session", function(){ return i("codecrafters-frontend/models/session.ts");});
d("codecrafters-frontend/models/site-feedback-submission", function(){ return i("codecrafters-frontend/models/site-feedback-submission.ts");});
d("codecrafters-frontend/models/slack-integration", function(){ return i("codecrafters-frontend/models/slack-integration.ts");});
d("codecrafters-frontend/models/solution-comparison", function(){ return i("codecrafters-frontend/models/solution-comparison.ts");});
d("codecrafters-frontend/models/submission-evaluation", function(){ return i("codecrafters-frontend/models/submission-evaluation.ts");});
d("codecrafters-frontend/models/submission", function(){ return i("codecrafters-frontend/models/submission.ts");});
d("codecrafters-frontend/models/subscription", function(){ return i("codecrafters-frontend/models/subscription.ts");});
d("codecrafters-frontend/models/team-membership", function(){ return i("codecrafters-frontend/models/team-membership.ts");});
d("codecrafters-frontend/models/team-payment-flow", function(){ return i("codecrafters-frontend/models/team-payment-flow.ts");});
d("codecrafters-frontend/models/team-payment-method-update-request", function(){ return i("codecrafters-frontend/models/team-payment-method-update-request.ts");});
d("codecrafters-frontend/models/team-payment-method", function(){ return i("codecrafters-frontend/models/team-payment-method.ts");});
d("codecrafters-frontend/models/team-pilot", function(){ return i("codecrafters-frontend/models/team-pilot.ts");});
d("codecrafters-frontend/models/team-subscription", function(){ return i("codecrafters-frontend/models/team-subscription.ts");});
d("codecrafters-frontend/models/team", function(){ return i("codecrafters-frontend/models/team.ts");});
d("codecrafters-frontend/models/track-leaderboard-entry", function(){ return i("codecrafters-frontend/models/track-leaderboard-entry.ts");});
d("codecrafters-frontend/models/trusted-community-solution-evaluation", function(){ return i("codecrafters-frontend/models/trusted-community-solution-evaluation.ts");});
d("codecrafters-frontend/models/upvote", function(){ return i("codecrafters-frontend/models/upvote.ts");});
d("codecrafters-frontend/models/user-profile-event", function(){ return i("codecrafters-frontend/models/user-profile-event.ts");});
d("codecrafters-frontend/models/user", function(){ return i("codecrafters-frontend/models/user.ts");});
d("codecrafters-frontend/models/view", function(){ return i("codecrafters-frontend/models/view.ts");});
d("codecrafters-frontend/router", function(){ return i("codecrafters-frontend/router.ts");});
d("codecrafters-frontend/serializers/application", function(){ return i("codecrafters-frontend/serializers/application.js");});
d("codecrafters-frontend/serializers/repository", function(){ return i("codecrafters-frontend/serializers/repository.js");});
d("codecrafters-frontend/services/action-cable-consumer", function(){ return i("codecrafters-frontend/services/action-cable-consumer.ts");});
d("codecrafters-frontend/services/analytics-event-tracker", function(){ return i("codecrafters-frontend/services/analytics-event-tracker.ts");});
d("codecrafters-frontend/services/authenticator", function(){ return i("codecrafters-frontend/services/authenticator.ts");});
d("codecrafters-frontend/services/beacon", function(){ return i("codecrafters-frontend/services/beacon.ts");});
d("codecrafters-frontend/services/confetti", function(){ return i("codecrafters-frontend/services/confetti.ts");});
d("codecrafters-frontend/services/container-width", function(){ return i("codecrafters-frontend/services/container-width.ts");});
d("codecrafters-frontend/services/course-page-state", function(){ return i("codecrafters-frontend/services/course-page-state.ts");});
d("codecrafters-frontend/services/current-user-cache-storage", function(){ return i("codecrafters-frontend/services/current-user-cache-storage.ts");});
d("codecrafters-frontend/services/dark-mode", function(){ return i("codecrafters-frontend/services/dark-mode.ts");});
d("codecrafters-frontend/services/date", function(){ return i("codecrafters-frontend/services/date.ts");});
d("codecrafters-frontend/services/feature-flag-syncer", function(){ return i("codecrafters-frontend/services/feature-flag-syncer.ts");});
d("codecrafters-frontend/services/feature-flags", function(){ return i("codecrafters-frontend/services/feature-flags.ts");});
d("codecrafters-frontend/services/focus", function(){ return i("codecrafters-frontend/services/focus.ts");});
d("codecrafters-frontend/services/force-update", function(){ return i("codecrafters-frontend/services/force-update.ts");});
d("codecrafters-frontend/services/layout", function(){ return i("codecrafters-frontend/services/layout.ts");});
d("codecrafters-frontend/services/local-storage", function(){ return i("codecrafters-frontend/services/local-storage.ts");});
d("codecrafters-frontend/services/meta-data", function(){ return i("codecrafters-frontend/services/meta-data.ts");});
d("codecrafters-frontend/services/monthly-challenge-banner", function(){ return i("codecrafters-frontend/services/monthly-challenge-banner.ts");});
d("codecrafters-frontend/services/page-view-tracker", function(){ return i("codecrafters-frontend/services/page-view-tracker.ts");});
d("codecrafters-frontend/services/sentry", function(){ return i("codecrafters-frontend/services/sentry.ts");});
d("codecrafters-frontend/services/session-token-storage", function(){ return i("codecrafters-frontend/services/session-token-storage.ts");});
d("codecrafters-frontend/services/store", function(){ return i("codecrafters-frontend/services/store.ts");});
d("codecrafters-frontend/services/time", function(){ return i("codecrafters-frontend/services/time.ts");});
d("codecrafters-frontend/services/user-syncer", function(){ return i("codecrafters-frontend/services/user-syncer.ts");});
d("codecrafters-frontend/services/utm-campaign-id-tracker", function(){ return i("codecrafters-frontend/services/utm-campaign-id-tracker.ts");});
d("codecrafters-frontend/services/version-tracker", function(){ return i("codecrafters-frontend/services/version-tracker.ts");});
d("codecrafters-frontend/services/visibility", function(){ return i("codecrafters-frontend/services/visibility.ts");});
d("codecrafters-frontend/services/web-vitals", function(){ return i("codecrafters-frontend/services/web-vitals.ts");});
d("codecrafters-frontend/transforms/boolean", function(){ return i("codecrafters-frontend/transforms/boolean.js");});
d("codecrafters-frontend/transforms/date", function(){ return i("codecrafters-frontend/transforms/date.js");});
d("codecrafters-frontend/transforms/number", function(){ return i("codecrafters-frontend/transforms/number.js");});
d("codecrafters-frontend/transforms/string", function(){ return i("codecrafters-frontend/transforms/string.js");});
d("codecrafters-frontend/utils/base-route", function(){ return i("codecrafters-frontend/utils/base-route.ts");});
d("codecrafters-frontend/utils/blend-colors", function(){ return i("codecrafters-frontend/utils/blend-colors.ts");});
d("codecrafters-frontend/utils/blocks", function(){ return i("codecrafters-frontend/utils/blocks.ts");});
d("codecrafters-frontend/utils/code-mirror-collapse-unchanged-gutter", function(){ return i("codecrafters-frontend/utils/code-mirror-collapse-unchanged-gutter.ts");});
d("codecrafters-frontend/utils/code-mirror-documents", function(){ return i("codecrafters-frontend/utils/code-mirror-documents.ts");});
d("codecrafters-frontend/utils/code-mirror-gutter-rs", function(){ return i("codecrafters-frontend/utils/code-mirror-gutter-rs.ts");});
d("codecrafters-frontend/utils/code-mirror-highlight-newlines", function(){ return i("codecrafters-frontend/utils/code-mirror-highlight-newlines.ts");});
d("codecrafters-frontend/utils/code-mirror-themes", function(){ return i("codecrafters-frontend/utils/code-mirror-themes.ts");});
d("codecrafters-frontend/utils/course-leaderboard-entry", function(){ return i("codecrafters-frontend/utils/course-leaderboard-entry.js");});
d("codecrafters-frontend/utils/course-page-step-list", function(){ return i("codecrafters-frontend/utils/course-page-step-list.ts");});
d("codecrafters-frontend/utils/course-page-step-list/base-stages-completed-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/base-stages-completed-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/base-stages-step-group", function(){ return i("codecrafters-frontend/utils/course-page-step-list/base-stages-step-group.ts");});
d("codecrafters-frontend/utils/course-page-step-list/course-completed-step-group", function(){ return i("codecrafters-frontend/utils/course-page-step-list/course-completed-step-group.ts");});
d("codecrafters-frontend/utils/course-page-step-list/course-completed-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/course-completed-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/course-stage-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/course-stage-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/extension-completed-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/extension-completed-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/extension-step-group", function(){ return i("codecrafters-frontend/utils/course-page-step-list/extension-step-group.ts");});
d("codecrafters-frontend/utils/course-page-step-list/introduction-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/introduction-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/progress-indicator", function(){ return i("codecrafters-frontend/utils/course-page-step-list/progress-indicator.ts");});
d("codecrafters-frontend/utils/course-page-step-list/setup-step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/setup-step.ts");});
d("codecrafters-frontend/utils/course-page-step-list/step-group", function(){ return i("codecrafters-frontend/utils/course-page-step-list/step-group.ts");});
d("codecrafters-frontend/utils/course-page-step-list/step", function(){ return i("codecrafters-frontend/utils/course-page-step-list/step.ts");});
d("codecrafters-frontend/utils/fetch-file-contents-if-needed", function(){ return i("codecrafters-frontend/utils/fetch-file-contents-if-needed.ts");});
d("codecrafters-frontend/utils/file-comparison", function(){ return i("codecrafters-frontend/utils/file-comparison.ts");});
d("codecrafters-frontend/utils/group-diff-lines-into-chunks", function(){ return i("codecrafters-frontend/utils/group-diff-lines-into-chunks.ts");});
d("codecrafters-frontend/utils/highlighter-cache", function(){ return i("codecrafters-frontend/utils/highlighter-cache.ts");});
d("codecrafters-frontend/utils/leaderboard-poller", function(){ return i("codecrafters-frontend/utils/leaderboard-poller.ts");});
d("codecrafters-frontend/utils/lodash-utils", function(){ return i("codecrafters-frontend/utils/lodash-utils.js");});
d("codecrafters-frontend/utils/logstream", function(){ return i("codecrafters-frontend/utils/logstream.ts");});
d("codecrafters-frontend/utils/params-from-route-info", function(){ return i("codecrafters-frontend/utils/params-from-route-info.ts");});
d("codecrafters-frontend/utils/parse-diff-as-document", function(){ return i("codecrafters-frontend/utils/parse-diff-as-document.ts");});
d("codecrafters-frontend/utils/poller", function(){ return i("codecrafters-frontend/utils/poller.ts");});
d("codecrafters-frontend/utils/pre-challenge-assessment-section-list", function(){ return i("codecrafters-frontend/utils/pre-challenge-assessment-section-list.ts");});
d("codecrafters-frontend/utils/replace-meta-tag", function(){ return i("codecrafters-frontend/utils/replace-meta-tag.js");});
d("codecrafters-frontend/utils/repository-poller", function(){ return i("codecrafters-frontend/utils/repository-poller.ts");});
d("codecrafters-frontend/utils/route-info-metadata", function(){ return i("codecrafters-frontend/utils/route-info-metadata.ts");});
d("codecrafters-frontend/utils/scroll-to-top", function(){ return i("codecrafters-frontend/utils/scroll-to-top.js");});
d("codecrafters-frontend/utils/testimonials-data", function(){ return i("codecrafters-frontend/utils/testimonials-data.js");});
d("codecrafters-frontend/utils/time-formatting", function(){ return i("codecrafters-frontend/utils/time-formatting.ts");});
d("codecrafters-frontend/utils/track-leaderboard-entry", function(){ return i("codecrafters-frontend/utils/track-leaderboard-entry.js");});
d("codecrafters-frontend/services/-ea-motion", function(){ return i("codecrafters-frontend/services/-ea-motion.js");});
d("codecrafters-frontend/services/cookies", function(){ return i("codecrafters-frontend/services/cookies.js");});
d("codecrafters-frontend/data-adapter", function(){ return i("codecrafters-frontend/data-adapter.js");});
d("codecrafters-frontend/initializers/ember-data", function(){ return i("codecrafters-frontend/initializers/ember-data.js");});
d("codecrafters-frontend/services/store", function(){ return i("codecrafters-frontend/services/store.js");});
d("codecrafters-frontend/services/keyboard", function(){ return i("codecrafters-frontend/services/keyboard.js");});
d("codecrafters-frontend/services/page-title", function(){ return i("codecrafters-frontend/services/page-title.js");});
d("codecrafters-frontend/services/ember-sortable-internal-state", function(){ return i("codecrafters-frontend/services/ember-sortable-internal-state.js");});
d("codecrafters-frontend/instance-initializers/clear-double-boot", function(){ return i("codecrafters-frontend/instance-initializers/clear-double-boot.js");});
d("codecrafters-frontend/locations/none", function(){ return i("codecrafters-frontend/locations/none.js");});
d("codecrafters-frontend/services/fastboot", function(){ return i("codecrafters-frontend/services/fastboot.js");});
d("codecrafters-frontend/component-managers/glimmer", function(){ return i("codecrafters-frontend/component-managers/glimmer.js");});
d("codecrafters-frontend/instance-initializers/sentry-performance", function(){ return i("codecrafters-frontend/instance-initializers/sentry-performance.js");});
d("codecrafters-frontend/services/-ensure-registered", function(){ return i("codecrafters-frontend/services/-ensure-registered.js");});
d("codecrafters-frontend/initializers/app-version", function(){ return i("codecrafters-frontend/initializers/app-version.js");});
d("codecrafters-frontend/instance-initializers/head-browser", function(){ return i("codecrafters-frontend/instance-initializers/head-browser.js");});
d("codecrafters-frontend/services/head-data", function(){ return i("codecrafters-frontend/services/head-data.js");});
d("codecrafters-frontend/utils/titleize", function(){ return i("codecrafters-frontend/utils/titleize.js");});
d("codecrafters-frontend/initializers/viewport-config", function(){ return i("codecrafters-frontend/initializers/viewport-config.js");});
d("codecrafters-frontend/services/in-viewport", function(){ return i("codecrafters-frontend/services/in-viewport.js");});
d("codecrafters-frontend/templates/application", function(){ return i("codecrafters-frontend/templates/application.hbs");});
d("codecrafters-frontend/controllers/application", function(){ return i("codecrafters-frontend/controllers/application.js");});
d("codecrafters-frontend/routes/application", function(){ return i("codecrafters-frontend/routes/application.js");});
d("codecrafters-frontend/templates/catalog", function(){ return i("codecrafters-frontend/templates/catalog.hbs");});
d("codecrafters-frontend/controllers/catalog", function(){ return i("codecrafters-frontend/controllers/catalog.ts");});
d("codecrafters-frontend/routes/catalog", function(){ return i("codecrafters-frontend/routes/catalog.ts");});
d("codecrafters-frontend/templates/concepts", function(){ return i("codecrafters-frontend/templates/concepts.hbs");});
d("codecrafters-frontend/controllers/concepts", function(){ return i("codecrafters-frontend/controllers/concepts.ts");});
d("codecrafters-frontend/routes/concepts", function(){ return i("codecrafters-frontend/routes/concepts.js");});
d("codecrafters-frontend/templates/course-overview", function(){ return i("codecrafters-frontend/templates/course-overview.hbs");});
d("codecrafters-frontend/controllers/course-overview", function(){ return i("codecrafters-frontend/controllers/course-overview.ts");});
d("codecrafters-frontend/routes/course-overview", function(){ return i("codecrafters-frontend/routes/course-overview.ts");});
d("codecrafters-frontend/templates/debug", function(){ return i("codecrafters-frontend/templates/debug.hbs");});
d("codecrafters-frontend/controllers/debug", function(){ return i("codecrafters-frontend/controllers/debug.ts");});
d("codecrafters-frontend/routes/debug", function(){ return i("codecrafters-frontend/routes/debug.ts");});
d("codecrafters-frontend/templates/join-course", function(){ return i("codecrafters-frontend/templates/join-course.hbs");});
d("codecrafters-frontend/controllers/join-course", function(){ return i("codecrafters-frontend/controllers/join-course.ts");});
d("codecrafters-frontend/routes/join-course", function(){ return i("codecrafters-frontend/routes/join-course.ts");});
d("codecrafters-frontend/templates/join-track", function(){ return i("codecrafters-frontend/templates/join-track.hbs");});
d("codecrafters-frontend/controllers/join-track", function(){ return i("codecrafters-frontend/controllers/join-track.ts");});
d("codecrafters-frontend/routes/join-track", function(){ return i("codecrafters-frontend/routes/join-track.ts");});
d("codecrafters-frontend/templates/join", function(){ return i("codecrafters-frontend/templates/join.hbs");});
d("codecrafters-frontend/controllers/join", function(){ return i("codecrafters-frontend/controllers/join.ts");});
d("codecrafters-frontend/routes/join", function(){ return i("codecrafters-frontend/routes/join.ts");});
d("codecrafters-frontend/templates/loading", function(){ return i("codecrafters-frontend/templates/loading.hbs");});
d("codecrafters-frontend/controllers/loading", function(){ return i("codecrafters-frontend/controllers/loading.js");});
d("codecrafters-frontend/templates/membership", function(){ return i("codecrafters-frontend/templates/membership.hbs");});
d("codecrafters-frontend/controllers/membership", function(){ return i("codecrafters-frontend/controllers/membership.js");});
d("codecrafters-frontend/routes/membership", function(){ return i("codecrafters-frontend/routes/membership.js");});
d("codecrafters-frontend/templates/partner", function(){ return i("codecrafters-frontend/templates/partner.hbs");});
d("codecrafters-frontend/controllers/partner", function(){ return i("codecrafters-frontend/controllers/partner.ts");});
d("codecrafters-frontend/routes/partner", function(){ return i("codecrafters-frontend/routes/partner.ts");});
d("codecrafters-frontend/templates/pay", function(){ return i("codecrafters-frontend/templates/pay.hbs");});
d("codecrafters-frontend/controllers/pay", function(){ return i("codecrafters-frontend/controllers/pay.ts");});
d("codecrafters-frontend/routes/pay", function(){ return i("codecrafters-frontend/routes/pay.ts");});
d("codecrafters-frontend/templates/refer", function(){ return i("codecrafters-frontend/templates/refer.hbs");});
d("codecrafters-frontend/controllers/refer", function(){ return i("codecrafters-frontend/controllers/refer.ts");});
d("codecrafters-frontend/routes/refer", function(){ return i("codecrafters-frontend/routes/refer.ts");});
d("codecrafters-frontend/templates/referral-link", function(){ return i("codecrafters-frontend/templates/referral-link.hbs");});
d("codecrafters-frontend/controllers/referral-link", function(){ return i("codecrafters-frontend/controllers/referral-link.ts");});
d("codecrafters-frontend/routes/referral-link", function(){ return i("codecrafters-frontend/routes/referral-link.ts");});
d("codecrafters-frontend/templates/settings", function(){ return i("codecrafters-frontend/templates/settings.hbs");});
d("codecrafters-frontend/controllers/settings", function(){ return i("codecrafters-frontend/controllers/settings.ts");});
d("codecrafters-frontend/routes/settings", function(){ return i("codecrafters-frontend/routes/settings.ts");});
d("codecrafters-frontend/templates/settings/account", function(){ return i("codecrafters-frontend/templates/settings/account.hbs");});
d("codecrafters-frontend/controllers/settings/account", function(){ return i("codecrafters-frontend/controllers/settings/account.ts");});
d("codecrafters-frontend/templates/settings/profile", function(){ return i("codecrafters-frontend/templates/settings/profile.hbs");});
d("codecrafters-frontend/controllers/settings/profile", function(){ return i("codecrafters-frontend/controllers/settings/profile.ts");});
d("codecrafters-frontend/routes/settings/index", function(){ return i("codecrafters-frontend/routes/settings/index.ts");});
d("codecrafters-frontend/templates/teams/pay", function(){ return i("codecrafters-frontend/templates/teams/pay.hbs");});
d("codecrafters-frontend/controllers/teams/pay", function(){ return i("codecrafters-frontend/controllers/teams/pay.js");});
d("codecrafters-frontend/routes/teams/pay", function(){ return i("codecrafters-frontend/routes/teams/pay.ts");});
d("codecrafters-frontend/templates/teams/create", function(){ return i("codecrafters-frontend/templates/teams/create.hbs");});
d("codecrafters-frontend/routes/teams/create", function(){ return i("codecrafters-frontend/routes/teams/create.js");});
d("codecrafters-frontend/templates/track", function(){ return i("codecrafters-frontend/templates/track.hbs");});
d("codecrafters-frontend/controllers/track", function(){ return i("codecrafters-frontend/controllers/track.ts");});
d("codecrafters-frontend/routes/track", function(){ return i("codecrafters-frontend/routes/track.ts");});
d("codecrafters-frontend/templates/update-required", function(){ return i("codecrafters-frontend/templates/update-required.hbs");});
d("codecrafters-frontend/controllers/update-required", function(){ return i("codecrafters-frontend/controllers/update-required.ts");});
d("codecrafters-frontend/routes/update-required", function(){ return i("codecrafters-frontend/routes/update-required.ts");});
d("codecrafters-frontend/templates/user", function(){ return i("codecrafters-frontend/templates/user.hbs");});
d("codecrafters-frontend/controllers/user", function(){ return i("codecrafters-frontend/controllers/user.ts");});
d("codecrafters-frontend/routes/user", function(){ return i("codecrafters-frontend/routes/user.ts");});
d("codecrafters-frontend/templates/vote", function(){ return i("codecrafters-frontend/templates/vote.hbs");});
d("codecrafters-frontend/controllers/vote", function(){ return i("codecrafters-frontend/controllers/vote.ts");});
d("codecrafters-frontend/routes/vote", function(){ return i("codecrafters-frontend/routes/vote.ts");});
d("codecrafters-frontend/templates/vote/course-extension-ideas", function(){ return i("codecrafters-frontend/templates/vote/course-extension-ideas.hbs");});
d("codecrafters-frontend/controllers/vote/course-extension-ideas", function(){ return i("codecrafters-frontend/controllers/vote/course-extension-ideas.ts");});
d("codecrafters-frontend/routes/vote/course-extension-ideas", function(){ return i("codecrafters-frontend/routes/vote/course-extension-ideas.js");});
d("codecrafters-frontend/templates/vote/course-ideas", function(){ return i("codecrafters-frontend/templates/vote/course-ideas.hbs");});
d("codecrafters-frontend/controllers/vote/course-ideas", function(){ return i("codecrafters-frontend/controllers/vote/course-ideas.ts");});
d("codecrafters-frontend/templates/welcome", function(){ return i("codecrafters-frontend/templates/welcome.hbs");});
d("codecrafters-frontend/controllers/welcome", function(){ return i("codecrafters-frontend/controllers/welcome.ts");});
d("codecrafters-frontend/routes/welcome", function(){ return i("codecrafters-frontend/routes/welcome.ts");});
d("codecrafters-frontend/templates/concept-group", function(){ return i("codecrafters-frontend/templates/concept-group.hbs");});
d("codecrafters-frontend/routes/concept-group", function(){ return i("codecrafters-frontend/routes/concept-group.ts");});
d("codecrafters-frontend/templates/contest", function(){ return i("codecrafters-frontend/templates/contest.hbs");});
d("codecrafters-frontend/routes/contest", function(){ return i("codecrafters-frontend/routes/contest.ts");});
d("codecrafters-frontend/routes/contests", function(){ return i("codecrafters-frontend/routes/contests.ts");});
d("codecrafters-frontend/routes/courses", function(){ return i("codecrafters-frontend/routes/courses.js");});
d("codecrafters-frontend/routes/index", function(){ return i("codecrafters-frontend/routes/index.js");});
d("codecrafters-frontend/routes/logged-in", function(){ return i("codecrafters-frontend/routes/logged-in.js");});
d("codecrafters-frontend/routes/login", function(){ return i("codecrafters-frontend/routes/login.js");});
d("codecrafters-frontend/templates/not-found", function(){ return i("codecrafters-frontend/templates/not-found.hbs");});
d("codecrafters-frontend/routes/not-found", function(){ return i("codecrafters-frontend/routes/not-found.js");});
d("codecrafters-frontend/routes/perk", function(){ return i("codecrafters-frontend/routes/perk.ts");});
d("codecrafters-frontend/routes/perk/claim", function(){ return i("codecrafters-frontend/routes/perk/claim.ts");});
d("codecrafters-frontend/templates/team", function(){ return i("codecrafters-frontend/templates/team.hbs");});
d("codecrafters-frontend/routes/team", function(){ return i("codecrafters-frontend/routes/team.js");});
d("codecrafters-frontend/routes/tracks", function(){ return i("codecrafters-frontend/routes/tracks.js");});
d("codecrafters-frontend/templates/account-deleted", function(){ return i("codecrafters-frontend/templates/account-deleted.hbs");});
d("codecrafters-frontend/templates/catalog-loading", function(){ return i("codecrafters-frontend/templates/catalog-loading.hbs");});
d("codecrafters-frontend/templates/head", function(){ return i("codecrafters-frontend/templates/head.hbs");});
d("codecrafters-frontend/templates/vote-loading", function(){ return i("codecrafters-frontend/templates/vote-loading.hbs");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("codecrafters-frontend/initializers/ajax", function(){ return i("codecrafters-frontend/initializers/ajax.js");});
d("codecrafters-frontend/initializers/error-handler", function(){ return i("codecrafters-frontend/initializers/error-handler.js");});
d("codecrafters-frontend/instance-initializers/setup-fetch", function(){ return i("codecrafters-frontend/instance-initializers/setup-fetch.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["badges"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/badges.js");
    }
  },
  {
    names: ["concept-admin","concept-admin.basic-details","concept-admin.blocks","concept-admin.question","concept-admin.questions","concept-admin.index"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/concept-admin.js");
    }
  },
  {
    names: ["concept"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/concept.js");
    }
  },
  {
    names: ["course-admin","course-admin.buildpack","course-admin.buildpacks","course-admin.code-example-evaluator","course-admin.code-example","course-admin.feedback","course-admin.insights","course-admin.stage-insights-index","course-admin.stage-insights","course-admin.submissions","course-admin.tester-version","course-admin.tester-versions","course-admin.update","course-admin.updates","course-admin.code-example-evaluators","course-admin.loading"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/course-admin.js");
    }
  },
  {
    names: ["course","course.base-stages-completed","course.completed","course.extension-completed","course.introduction","course.setup","course.stage","course.stage.code-examples","course.stage.instructions","course.stage.screencasts","course.stage.concepts"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/course.js");
    }
  },
  {
    names: ["demo","demo.code-mirror","demo.dark-mode-toggle","demo.file-contents-card","demo.file-diff-card","demo.index"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/demo.js");
    }
  },
  {
    names: ["code-walkthrough"],
    load: function() {
      return import("codecrafters-frontend/assets/_route_/code-walkthrough.js");
    }
  },
]



if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('codecrafters-frontend/app')['default'].create({"name":"codecrafters-frontend","version":"0.0.3+9cb98b64"});
  }
}


